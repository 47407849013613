.Body {
    background-color: #F6F7F8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.TabMenu {
    text-align: center;
    padding: 1rem 5%;

    position: sticky;
    top: 0;
    z-index: 200;

    background-color: #fff;

}

.TabMenu nav {
    display: flex;
    justify-content: center;
}

.TabMenu a {
    text-decoration: none;
    color: #000;
}

.TabMenu a:hover {
    cursor: pointer;
}

.FormError {
    padding: 2rem;
    background-color: #fee3e2;

    width: 50%;
    margin: 4rem 0;

    color: #C3403E;
    
}

.FormError img {
    margin-right: 1rem;
}

.FormError ul {
    padding-left: 1rem;
}

.Card {
    width: 50%;
    margin: 4rem 0;

    position: relative;
}

.Card a:hover {
    cursor: pointer;
}

.Card h5 {
    margin-bottom: 1rem;
}

.CardBody {
    padding: 2rem;

    background-color: #fff;
}

.CardFooter div{
    display: flex;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    
}

.CardFooter div:nth-of-type(2n){
    justify-content: end;
    
}

.ExistingResume {
    margin-top: 1rem;
    padding: 2rem;
    
}

.ExistingResume span {
    color: rgb(141, 139, 139);
}

.ExistingResume p {
    margin-top: 1rem;
}
.CoverLetter textarea {
    resize: none;
    overflow: hidden;
    min-height: 200px;

    width: 100%;
}

.EmployerQuestions select {
    margin: 1rem 0;
    padding: 0.5rem;
    width: 100%;
}

.EmployerQuestions select option {
    padding: 1rem;
}


@media only screen and (max-width: 1200px) {
    .Card, .FormError {
        width: 70%;
    }
}

@media only screen and (max-width: 700px) {
    .Card, .FormError {
        width: 90%;
    }
}

@media only screen and (max-width: 500px) {
    .Card, .FormError {
        width: 95%;
    }
}