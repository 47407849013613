.Settings {
    padding: 2rem 0;
}

.Card {
    background-color: #fff;
    padding: 2rem 0;
    margin-top: 2rem;
    margin-bottom: 2rem;

    width: 40%;
}

.CardSection {
    padding: 1rem;
    border-top: 1px solid rgb(150, 150, 150);
    margin:0;
}

.CardSection h6 {
    color: #3B4DE4
}

.CardSection span:hover {
    cursor: pointer;
}

.CardSection img {
    transition: ease-in-out 0.2s;
}

.CardSection img:hover {
    cursor: pointer;
}

.CardForm {
    padding: 1rem 0;
}

.CardForm input {
    width: 100%;
    padding: 0.5rem;

    margin-bottom: 1rem;
}

.PasswordUpdatedConfirmation {
    background-color: #cde0c1;

    padding: 1rem;
}

@media only screen and (max-width: 1200px) {
    .Card {
        width: 60%;
    }
}

@media only screen and (max-width: 875px) {
    .Card {
        width: 70%;
    }
}

@media only screen and (max-width: 720px) {
    .Card {
        width: 100%;
    }
}