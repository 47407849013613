.Backdrop {
    height: 100vh;
    background-color: transparent;
    position: absolute;
    width: 100%;
}

.SearchContainer {
    background-color: #3B4DE4;

}

.Search {
    padding: 4rem 0 3rem 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.SearchRow {
    width: 80%;
}

.SearchRow p {
    margin-top: 1rem;
    color: #fff;
}

.SearchRow a {
    position: relative;
    margin-left: 0.5rem;
}

.SearchRow a:hover {
    cursor: pointer;
}

.Search input {
    width: 100%;
    border: none;

    padding: 0.5rem 1rem;

}

.Search input:focus {
    outline: none !important;

    z-index: 100;
}

.SuggestionsList {

    background-color: #fff;
    box-shadow: 0 2px;
    position: absolute;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 12px;
    right: 12px;
}

.SuggestionsList div {
    padding: 1rem;
}

.SearchResult:hover {
    cursor: pointer;
    background-color: #0A0F34;
    color: #fff;
}

.SignIn {
    padding: 2%;
    text-align: center;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;


}

/* .SignIn span {
    padding: 0 1rem
} */

.SignIn a {
    position: relative;
    text-decoration: none;
}

.SignIn a:hover {
    cursor: pointer;
}

.SignInRow {

    width: 50%;
    display: flex;
    justify-content: center;
}

.SignIn img {
    position:absolute;
    bottom: -2rem;

    width: 15%;
    margin-top: 1rem;
}

.Section {
    margin: 2rem 0;
}

.Section h4{
    margin: 1rem;
}

.Section p {
    margin-bottom: 2rem;
}

.SectionRow {
    height: 270px;
}

.Step {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.Step div {
    height: 40px;
    width: 40px;

    margin-bottom: 1rem;

    border-radius: 200px;

    background-color: #3B4DE4;
    color: #fff;

    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;


}

.Step img {
    width: 90%;
}


.PopularSearches {
    text-align: center;

    padding: 2rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.PopularSearches h4 {
    padding: 0 0 2rem 0;
}

.PopularSearchesRow {
    width: 80%;
}

.Tab {
    margin:0;
    padding:0;
}

.Tab:hover {
    cursor: pointer;
    color: #3B4DE4;
}

.Tab:hover div {
    background-position:right bottom;
}


.TabSelect {
    height: 3px;
    /* background-color: #e0e0e0; */

    margin-top: 1rem;

    background: linear-gradient(to right, #e0e0e0 50%, #3B4DE4 50%);
    background-size: 200% 100%;
    background-position:left bottom;

    transition:all 0.1s ease;
}


/* .TabSelectActive {
    background-color: linear-gradient(to right, #3B4DE4 50%, #3B4DE4 50%) !important;
} */

.Filter {
    position: relative;
}

.Filter button img {
    pointer-events: none;
}


@media only screen and (max-width: 1200px) {
    .SectionRow {
        height: 250px;
    }
    .HideOnMobile {
        display: none;
    }
};

@media only screen and (max-width: 990px) {
    .Search input {
        margin: 0.2rem 0;
    }
    .Search button {
        width: 100%;
        margin: 0.5rem 0;
    }
    .SignIn {
        padding: 2rem 0;
    }
    .SignIn img {
        width: 35% !important;
    }
    .SignInRow {

        width: 70%;
        display: flex;
        justify-content: center;
    }

}

@media only screen and (max-width: 767px) {
    .SignInRow {

        width: 100%;
        display: flex;
        justify-content: center;
    }
    .SectionRow {
        height: 200px;
    }
    .PopularSearchesRow {
        width: 90%;
    }
    .SignIn {
        padding-top: 4%;
        padding-bottom: 4%;
    }
    .SignIn p{
        padding-left: 5.5%;
        padding-right: 5.5%;
    }
}