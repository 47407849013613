.JobPosting {
    padding: 2rem 0;
    width: 70%;

}

.SaveButton {
    margin-left: 2rem;
    padding: 0.6rem;
    background-color: transparent;
    color: #3B4DE4; 
    border: 1px solid #3B4DE4;
}

.SaveButton:hover {
    background-color: #3B4DE4;
    color: #FFF;

    cursor: pointer;
}