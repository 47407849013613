.Navbar {
    padding: 1rem 0 ;

    position: relative;
}

.Navbar a{
    margin-right: 2rem;
    padding: 0 0 0.2rem 0;
    position: relative;

    text-decoration: none;
    
}

.Navbar a:hover {
    cursor: pointer;
}

.Navbar img {
    position: relative;
    padding: 0;
}

.Navbar img:hover {
    cursor: pointer;
}

.Logo {
    font-weight: bold;
    color: #000;

    text-decoration: none !important;
}

.ProfileDropdown {
    position: absolute;
    top: 100%;
    left: 0;

    background-color: #fff;
    width: 100%;

    border-top: 1px solid;

    box-shadow: 0 2px;

    z-index: 700;
}

.ProfileDropdown a {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;

    width: 100%;
    display: block;
}

.ProfileDropdown a:hover {
    cursor: pointer;
    background-color: #0A0F34;
    color: #fff !important;
}

.NavbarMobile {
    display: none;
    justify-content: space-between;
    align-items: center;
}

.NavbarMobileModalContainer {
    display: none;
}

.NavbarMobileModal {

    background-color: #060606ee;
    color: #eee;

    height: 100vh !important;

    padding: 5vh 0;

    overflow: hidden !important;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
}

.NavbarMobileModal a {
    color: #fff;
    text-decoration: none;

    margin: 3% 0;
    font-size: 1.5rem;
}


@media only screen and (max-width: 950px) {
    .NavbarMobile {
        display: flex;
    }
    .NavbarMobileModalContainer {
        display: block;
    }
    .Navbar {
        display: none;
    }
}