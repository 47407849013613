.CreateResume h4 {
    margin-bottom: 2rem;
}

.TabMenu {
    text-align: center;
    padding: 1rem 5%;

    position: sticky;
    top: 0;
    z-index: 200;

    background-color: #fff;

}

.TabMenu nav {
    display: flex;
    justify-content: center;
}

.TabMenu a {
    text-decoration: none;
    color: #000;
}

.Body {
    background-color: #F6F7F8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.Card {
    width: 50%;
    margin: 10rem 0;

    position: relative;
}

.Card a:hover {
    cursor: pointer;
}

.Card h5 {
    margin-bottom: 1rem;
}

.CardBody {
    padding: 2rem;

    background-color: #fff;
}

.Modal {
    width: 100%;
    box-shadow: 0 2px;
    top:0;
    left:0;

}

.Modal input {
    display: block;
    margin-bottom: 1rem;

    padding: 0.5rem;

    width: 100%;
    
}

.Modal select {
    width: 100%;
    padding: 0.5rem;

    margin-bottom: 1rem;
}

.Modal textarea {
    width: 100%;
    padding: 0.5rem;

    margin-bottom: 1rem;
}

.PersonalDetailsModal {

    width: 100%;

    position: absolute;
    z-index: 100;

    padding: 2rem;

    background-color: #fff;
}

.PersonalDetailsModal button {
    margin-top: 1rem;
}

.CareerHistoryModal {

    position: absolute;
    z-index: 100;

    padding: 2rem;

    background-color: #fff;
}

.EducationModal {

    position: absolute;
    z-index: 100;

    padding: 2rem;

    background-color: #fff;
}

.SkillsModal {
    position: absolute;
    z-index: 100;

    padding: 2rem;

    background-color: #fff;

    height: auto;
}

.SkillsModal input {
    margin: 0;
}

.Skill {
    background-color: #686e9ad2;
    color: #fff;

    text-align: center;

    padding: 0.5rem;

    position: relative;
}

.Skill img {
    position: absolute;
    top: 10%;
    right: 2%;
}


.ScrollMarker {
    position: absolute;
    top: -15rem;
}

.DropdownList {

    background-color: #0A0F34;
    box-shadow: 0 2px;
    position: absolute;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 12px;
    right: 12px;

    color: #fff;

    z-index: 1000;
}

.DropdownList div {
    padding: 1rem;
}

.DropListItem:hover {
    cursor: pointer;
    background-color: #686e9ad2;
    color: #fff;
}

@media only screen and (max-width: 1200px) {
    .Card {
        width: 70%;
    }
}

@media only screen and (max-width: 975px) {
    .TabMenu nav{
        display: none;    
    }
}

@media only screen and (max-width: 700px) {
    .Card {
        width: 90%;
    }
}

@media only screen and (max-width: 500px) {
    .Card {
        width: 95%;
    }
}