.DarkButton {
    background-color: #0A0F34;
    color: #fff;

    border: none;

    padding: 0.5rem 1rem;
}

.BlueButton {
    background-color: #3B4DE4;
    color: #fff;

    border: none;

    padding: 0.5rem 1rem;
}

.Underline {
    height: 2px;

    background-color: #0A0F34;

    position: absolute;
    bottom: 0;
    left:0;

    transition: all ease-in-out 0.2s;

}

.FilterButton {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    
    width:100%;
    padding: 0.5rem 0;

    font-size: 0.8rem;
}
.DarkFilterButton {
    background-color: transparent;
    color: #0A0F34;
    border: 1px solid #0A0F34;
    
    width:100%;

    padding: 0.8rem 0;
    margin-bottom: 1rem;

    font-size: 0.8rem;
}


.FilterAppliedButton {
    background-color: #0A0F34;
    color: #fff;
    border: 1px solid #fff;
    
    width:100%;
    padding: 0.5rem 0;

    font-size: 0.8rem;
}