.JobResults {
    padding: 2rem 0;
}

.Card {
    padding: 1rem;
    border: 1px solid;
}

.Card h3 {
    text-transform: capitalize;
    color: #3B4CE4;
}

.Card ul {
    padding: 0 0.8rem;
}

.NeedSomeHelp {
    text-align: center;

    background-color:  #F6F7F8;
    padding: 2rem 1rem;

}

.NeedSomeHelp h5 {
    margin-bottom: 2rem;
}

@media only screen and (max-width: 767px) {
    .NeedSomeHelp {
        width: 95%;
        padding: 2rem 0;
        margin: 2rem auto;
    }
    .NeedSomeHelp p {
        padding: 0 11%;
    }
    .NeedSomeHelp h5 {
        padding: 0 11%;
    }
}