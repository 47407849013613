.Backdrop {
    height: 100vh;
    background-color: transparent;
    position: absolute;
    width: 100%;

}

.SearchContainer {
    background-color: #3B4DE4;

}

.Search {
    padding: 4rem 0 3rem 0;

    display: flex;
    justify-content: center;
    align-items: center;
}

.SearchRow {
    width: 80%;
}

.SearchRow p {
    margin-top: 1rem;
    color: #fff;
}

.SearchRow a {
    position: relative;
    margin-left: 0.5rem;
}

.SearchRow a:hover {
    cursor: pointer;
}

.Search input {
    width: 100%;
    border: none;

    padding: 0.5rem 1rem;

}

.Search input:focus {
    outline: none !important;

    z-index: 100;
}

.DropdownList {

    background-color: #fff;
    box-shadow: 0 2px;
    position: absolute;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 12px;
    right: 12px;
}

.DropdownList div {
    padding: 1rem;
}

.DropListItem:hover {
    cursor: pointer;
    background-color: #0A0F34;
    color: #fff;
}

.Filter {
    position: relative;
}

.Filter button img {
    pointer-events: none;
}

.UpsideDownImg {
    transform: rotate(180deg);
}

@media only screen and (max-width: 990px) {
    .Search input {
        margin: 0.2rem 0;
    }
    .Search button {
        width: 100%;
        margin: 0.5rem 0;
    }
}

@media only screen and (max-width: 500px) {
    .Search {
        padding: 1rem 0;
    }
    .Search input {
        margin: 0.2rem 0;
    }
    .Search button {
        width: 100%;
        margin: 0.5rem 0;
    }
    .SearchRow {
        width: 90%;
    }
}