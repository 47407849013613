.SavedJobs {
    padding: 4rem 0;
}

.Tab {
    position: relative;

    text-decoration: none;
    color: #000;

    padding-bottom: 0.5rem;

    cursor: pointer;
}

.Tab:hover {
    color: #000;
}

.Card {
    background-color: #fff;
    padding: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    width: 70%;
}

.Card a {
    text-decoration: none;
    color: #000;
}

.Card h6 {
    color: #3B4DE4;
}