.SignIn {
    padding: 4rem 0;
    background-color: #F6F7F8;
}

.Card {
    width: 55%;
    background-color: #fff;
    border: 1px solid rgb(229, 229, 229);
    padding: 2rem;

    position: relative;

    z-index: 100;
}

.Card h2 {
    margin-bottom: 2rem;
    
}

.Card input {
    width: 100%;
    border: 1px solid;

    padding: 0.5rem 1rem;
    margin-bottom: 1rem;

}

.DesktopImage {
    position: absolute;
    left: -15%;
    bottom: -2%;

    width: 15%;
}

.MobileImage {
    position: absolute;
    width: 20%;
    bottom: 0;
    right: 0;

    display: none;
}

.Card p {
    margin-bottom: 0rem;
}

.Card p span a {
    position: relative;
    font-weight: 700;
    color: #3B4DE4 !important;

    text-decoration: none;

}

.Card p span a:hover {
    cursor: pointer;
}

.FormError {
    margin-bottom: 1rem !important;
}

.MainFormError {
    background-color: #FEE3E2;
    padding: 1rem;
    margin-bottom: 1rem;
    color: #b71e1e;
}

.MainFormError img {
    margin-right: 1rem;
}

@media only screen and (max-width: 1100px) {
    .Card {
        width: 90%;
    }
    .DesktopImage {
        display: none;
    }
    .MobileImage {
        display: block
    }
};

@media only screen and (max-width: 750px) {
    .SignIn {
        padding: 4rem 0;
    }
    .Card {
        width: 100%;
        padding: 0;
        margin: 0 !important;
    }
    .MobileImage {
        width: 35% !important;
    }
};