.Footer {
    background-color: #D6D6D6;

    padding: 4rem 0;
    min-height: 300px;
}

.Footer a {
    display: block;

    margin: 1rem 0;
    color: #3b4ce4c7 !important; 

    font-weight: 300;
}

.FooterCol {
    margin-bottom: 2rem;
}

.FooterRowTwo a {
    margin: 0.5rem 0;
}