
.Dashboard {
    background-color: #F6F7F8;
    padding-bottom: 2rem;
}

.DashboardHeader {
    background-color: #3B4DE4;
    color: #fff;
    padding: 2rem 0;

    text-align: center;
}

.Dashboard h1, p, button {
    cursor: pointer;
}


/* .Dashboard header p {
    text-decoration: underline;
} */

.GetStarted {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 70%;

    padding: 2rem 0;

    margin-top: 2rem;
    margin-bottom: 2rem;

}

.GetStarted div {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem 0;

}

.Card {
    background-color: #fff;
    padding: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    width: 70%;
}

.Card h5 {
    margin-bottom: 2rem;
}

.Card button {
    width: auto;
}

.Modal {
    padding: 1rem 2rem;

    background-color: #fff;
    color: #000;

    text-align: left;

    position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 

    width: 60%;

    box-shadow: 0 2px;
}

.Modal h4 {
    margin-bottom: 2rem;
}

.Modal input {
    width: 100%;
    margin-bottom: 1rem;

    padding: 0.5rem;
}

.Modal button {
    margin-top: 1rem;
}

.Tag {
   margin-right: 1rem;
   padding: 0.5rem;
   
   font-size: 0.8rem;
}

@media only screen and (max-width: 850px) {
    .GetStarted {
        width: 90%;
    }
    .Modal {
        width: 80%;
    }
}

@media only screen and (max-width: 767px) {
    .GetStarted {
        padding: 2rem;
    }
    .Modal {
        width: 90%;
    }
    .Card {
        width: 90%;
    }
}