/* ----------------------------------------------
 * Generated by Animista on 2022-5-21 13:36:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation ping
 * ----------------------------------------
 */
 @-webkit-keyframes ping {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0.8;
    }
    80% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(2.2);
              transform: scale(2.2);
      opacity: 0;
    }
  }
  @keyframes ping {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0.8;
    }
    80% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(2.2);
              transform: scale(2.2);
      opacity: 0;
    }
  }

.VideoRecorder {
  width: 100%;
}


.Modal button {
    padding: 1rem;
    border: none;
    color: #fff;
    font-weight: 700;
}

.Modal .ExitButton {
    position: absolute;
    right: 0;

    background-color: #0A0F34;

    z-index: 100;
}

.RecordingState {
    padding: 1rem;

    background-color: #FF4949;

    color: #fff;

    font-weight: 700;
}

.RecordingIndicator {
	-webkit-animation: ping 1s ease-in-out infinite both;
	        animation: ping 1s ease-in-out infinite both;

    height: 20px;
    width: 20px;
    border-radius: 200px;

    margin-left: 1rem;

    background-color: #FFF;
}

.Question {
  padding: 0.5rem;
}